import ReactDOM from 'react-dom';
import Form from '@rjsf/material-ui';
import { bus } from './event-bus';

import StaticSelectWidget from './StaticSelectWidget';

const log = (type) => console.log.bind(console, type);

const submit = (d) => {
    console.log('Form Editor: sending data');
    bus.emit('hal-instance-configuration-change', d.formData);
};

const widgets = {
    staticSelectWidget: StaticSelectWidget,
};

let form;

bus.on('hal-instance-configuration-change-request', ()=> {
    console.log('Incoming configuration change request');
    form.submit();
});

bus.emitAsPromise('hal-instance-configuration-request', { time: new Date() })
    .then(response => {
        console.log('Form Editor: incoming data', response);

        const { signature, data, uiSchema } = response;

        ReactDOM.render((
            <Form ref={(el => form = el)}
                  schema={signature}
                  uiSchema={uiSchema}
                  widgets={widgets}
                  formData={data}
                  onChange={log('changed')}
                  onSubmit={submit}
                  onError={log('errors')}
                  autocomplete="off">
                <button style={{ display: "none" }}/>
            </Form>
        ), document.getElementById('app'));
    });


