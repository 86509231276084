import React, { Component } from 'react';
import { bus, channel } from './event-bus';

export default class StaticSelectWidget extends Component {
    constructor(props) {
        super(props);

        const { id, options, value, onChange } = this.props;
        const responseEvent = `hal-${channel}-${id}-items-response`;
        const dataRequestEvent = 'hal-data-request';

        this.state = {
            items: options.items || [{ value, label: value }],
        };

        this.onItemsResponse = (items) => {
            this.setState({
                items: items,
            });
        };

        const { service, field: fieldOrigin, linkTo, params } = options;

        if (service) {
            console.log('requesting data', dataRequestEvent, { service, params });
            bus.emitAsPromise(dataRequestEvent, { service, params })
                .then(this.onItemsResponse);
        }

        const populateLinkTo = (currentValue) => {
            if (linkTo) {
                const { field, service, paramName } = linkTo;

                const lastIndex = id.lastIndexOf(fieldOrigin) - 1;
                const linkToId = `${id.substr(0, lastIndex)}_${field}`;

                const data = {
                    params: {
                        [paramName]: currentValue,
                    },
                    service,
                    responseEvent: `hal-${channel}-${linkToId}-items-response`,
                };

                bus.emit(dataRequestEvent, data);
            }
        };

        populateLinkTo(value);

        this.onChangeHandler = (value) => {
            onChange(value);
            populateLinkTo(value);
        };

        bus.on(responseEvent, this.onItemsResponse);
    }

    render() {
        const {
            schema,
            id,
            label,
            required,
            disabled,
            readonly,
            value,
            multiple,
            autofocus,
            onBlur,
            onFocus,
            rawErrors,
            registry,
        } = this.props;
        const { SelectWidget } = registry.widgets;
        return (
            <SelectWidget
                schema={schema}
                id={id}
                options={{ enumOptions: this.state.items }}
                label={label}
                required={required}
                disabled={disabled}
                readonly={readonly}
                value={value}
                multiple={multiple}
                autofocus={autofocus}
                onChange={this.onChangeHandler}
                onBlur={onBlur}
                onFocus={onFocus}
                rawErrors={rawErrors}
            />
        );
    }
}